var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$t("linkOrder"),
        visible: _vm.dialogVisible,
        width: "80%",
        top: "12vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: function ($event) {
          return _vm.$emit("update:visible", false)
        },
      },
    },
    [
      _c("div", { staticClass: "search-block" }, [
        _c(
          "div",
          { staticClass: "search-item", staticStyle: { width: "305px" } },
          [
            _c(
              "el-input",
              {
                staticClass: "round-input",
                attrs: { placeholder: "Search Order" },
                nativeOn: {
                  keypress: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.handlerSearchKey.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.searchKey,
                  callback: function ($$v) {
                    _vm.searchKey = $$v
                  },
                  expression: "searchKey",
                },
              },
              [
                _c("el-button", {
                  attrs: { slot: "append", icon: "el-icon-search" },
                  on: { click: _vm.handlerSearchKey },
                  slot: "append",
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "table-block" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading",
                },
              ],
              ref: "singleTable",
              attrs: {
                data: _vm.tableData,
                height: "45vh",
                "highlight-current-row": "",
                stripe: "",
              },
              on: { "row-click": _vm.handleCurrentChange },
            },
            [
              _c("el-table-column", { attrs: { width: "55" } }, [
                _c("div", { staticClass: "check-circle" }, [
                  _c("em", { staticClass: "el-icon-check check-icon" }),
                ]),
              ]),
              _vm.currentCompany.companyName
                ? _c("el-table-column", {
                    attrs: {
                      prop: "orderNoRelative",
                      label: _vm.$t("orderNo"),
                      width: "280",
                    },
                  })
                : _c("el-table-column", {
                    attrs: { label: _vm.$t("orderNo"), width: "280" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(_vm._s(scope.row.purchaseOrderNumber)),
                            ]),
                            _c("div", [
                              _vm._v(_vm._s(scope.row.salesOrderNumber)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
              _c("el-table-column", {
                attrs: {
                  prop: "orderMode",
                  label: _vm.$t("orderMode"),
                  width: "110",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-tag",
                          {
                            attrs: {
                              type:
                                scope.row.orderMode === "term"
                                  ? "danger"
                                  : "primary",
                            },
                          },
                          [_vm._v(" " + _vm._s(scope.row.orderMode) + " ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "tradeType",
                  label: _vm.$t("orderType"),
                  width: "120",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "Order Status",
                  "show-overflow-tooltip": "",
                  width: "200",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "flex-row" }, [
                          _c("em", {
                            staticClass: "iconfont icon-yuandianzhong",
                            style: {
                              color:
                                _vm.orderStatusColor[
                                  _vm.orderStatusDescValues[scope.row.state].key
                                ],
                            },
                          }),
                          _c(
                            "span",
                            { staticStyle: { "font-weight": "bolder" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.orderStatusDescValues[scope.row.state]
                                    .value
                                )
                              ),
                            ]
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "buyerCompanyName",
                  label: "Buyer",
                  "show-overflow-tooltip": "",
                  width: "260",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "sellerCompanyName",
                  label: "Seller",
                  "show-overflow-tooltip": "",
                  width: "260",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "terminal",
                  label: _vm.orderType === "Ex-Wharf" ? "Terminal" : "Vessel",
                  "show-overflow-tooltip": "",
                  width: "180",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label:
                    (_vm.orderType === "Ex-Wharf"
                      ? "Loading Dates"
                      : "ETA Range") + " (From-To)",
                  "show-overflow-tooltip": "",
                  width: "250",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _c("span", { staticClass: "title-font-small" }),
                          _vm._v(
                            _vm._s(
                              scope.row[_vm.liftingDetail] &&
                                scope.row[_vm.liftingDetail].dateRangeStart
                                ? _vm
                                    .$moment(
                                      scope.row[_vm.liftingDetail]
                                        .dateRangeStart
                                    )
                                    .format("DD-MM-YYYY HH:mm")
                                : ""
                            ) + " "
                          ),
                        ]),
                        _c("div", [
                          _c("span", { staticClass: "title-font-small" }),
                          _vm._v(
                            _vm._s(
                              scope.row[_vm.liftingDetail] &&
                                scope.row[_vm.liftingDetail].dateRangeEnd
                                ? _vm
                                    .$moment(
                                      scope.row[_vm.liftingDetail].dateRangeEnd
                                    )
                                    .format("DD-MM-YYYY HH:mm")
                                : ""
                            ) + " "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "productGroup",
                  label: "Product/Grade/Specs",
                  width: "420",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(
                        scope.row.productGroup,
                        function (item, index) {
                          return _c(
                            "div",
                            { key: index },
                            [
                              _c(
                                "el-tag",
                                { attrs: { type: "info", size: "small" } },
                                [_vm._v(" " + _vm._s(item) + " ")]
                              ),
                            ],
                            1
                          )
                        }
                      )
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label:
                    _vm.orderType === "Ex-Wharf"
                      ? "Loading Quantity"
                      : "Delivered Quantity",
                  "show-overflow-tooltip": "",
                  align: "center",
                  width: "160",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(scope.row.products, function (item, index) {
                        return _c("div", { key: index }, [
                          _vm._v(" " + _vm._s(item.finalQty) + "mt "),
                        ])
                      })
                    },
                  },
                ]),
              }),
              _vm.orderType !== "Ex-Wharf"
                ? _c("el-table-column", {
                    attrs: { label: "Physical Supplier", width: "180" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return _vm._l(
                              scope.row.products || [],
                              function (item, index) {
                                return _c(
                                  "div",
                                  { key: index },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "no-bg-select",
                                        attrs: {
                                          disabled: "",
                                          value: item.physicalSupplierBdn,
                                          placeholder: "",
                                        },
                                      },
                                      _vm._l(
                                        _vm.companyOpts,
                                        function (company) {
                                          return _c("el-option", {
                                            key: company.value,
                                            attrs: {
                                              label: company.label,
                                              value: company.licenseNumber,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          },
                        },
                      ],
                      null,
                      false,
                      1124730221
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pagination-container" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "page-sizes": _vm.pageSizes,
              "page-size": _vm.pageSize,
              "current-page": _vm.pageIndex,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentPageChange,
            },
          }),
        ],
        1
      ),
      _c(
        "span",
        { staticClass: "dialog-footer-center" },
        [
          _c(
            "el-button",
            {
              staticStyle: { padding: "12px 45px" },
              attrs: { round: "", type: "primary" },
              on: { click: _vm.handlerConfirm },
            },
            [_vm._v(_vm._s(_vm.$t("confirm")))]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }